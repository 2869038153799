<template>
  <ion-page>
    <GoBackHeader defaultHref='/check-in' />

    <ion-content>
      <ion-list>
        <ion-list-header>
          {{ $t('trainingevents') }}
        </ion-list-header>

        <div v-if="!$apollo.loading">
          <div v-if="events && events.length">
            <ion-item v-for="event in events" :key="event.id" button @click="goToCheckedInList(event.id)" >
              <ion-label>
                <ion-text>
                  <h2>{{ event.title }}</h2>
                </ion-text>

                <ion-text color="moto">
                  <span class="text-sm block">{{ event.held_at|formatDateTime }}</span>
                </ion-text>

                <ion-text>
                  <span class="text-sm block">{{ event.total_checkins }} <span v-if="event.total_capacity">/ {{ event.total_capacity }}</span> {{ $t('capacitycheckinlabel') }}</span>
                </ion-text>
              </ion-label>
            </ion-item>
          </div>

          <div v-else>
            {{ $t('noeventstocheckin') }}
          </div>
        </div>

        <div v-else>
          <ion-list>
            <ion-item v-for="i in 10" :key="i">
              <ion-label>
                <ion-skeleton-text animated />
                <ion-skeleton-text animated />
                <ion-skeleton-text animated />
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
    import {GET_TRAINING_EVENTS} from "../../graphql/queries";
    import GoBackHeader from "@/components/navigation/GoBackHeader";
    import { SET_EVENT_ID_MUTATION } from '@/store/store-mutations';

    export default {
      components: {
        GoBackHeader,
      },
      apollo: {
        events:  {
          query: GET_TRAINING_EVENTS,
          update: data => data.getTrainingEvents,
          fetchPolicy: 'network-only',
        },
      },
      beforeCreate() {
        document.body.style.backgroundColor = '';
        document.documentElement.style.backgroundColor = '';
      },
      methods: {
        goToCheckedInList(eventId) {
          this.$store.commit(SET_EVENT_ID_MUTATION, eventId);
          this.$router.push({ name: this.$routeNames.CHECKED_IN_LIST });
        }
      }
    }
</script>

<style scoped>

    ion-toolbar {
        --background: var(--ion-color-tertiary)
    }
</style>
